<template>
  <div class="sign-data-table">
    <a-table
      :loading="loading"
      :data-source="dataSource"
      :columns="columns"
      :pagination="{
        current: pageNum,
        showSizeChanger: false,
        showQuickJumper: false,
        pageSize,
        total,
      }"
      :get-calendar-container="triggerNode=> triggerNode.parentNode"
      @change="onTableChange"
    >
      <template
        slot="index"
        slot-scope="text,records,index"
      >
        {{ (pageNum - 1) * pageSize + (index + 1) }}
      </template>
      <template
        slot="checkInSignTime"
        slot-scope="text,records"
      >
        <span>{{ getFormatTime(records.checkInSignTime) }}</span>
      </template>
      <template
        slot="bodyCheckTime"
        slot-scope="text,records"
      >
        <span>{{ getFormatTime(records.bodyCheckTime) }}</span>
      </template>
      <template
        slot="checkOutSignTime"
        slot-scope="text,records"
      >
        <span>{{ getFormatTime(records.checkOutSignTime) }}</span>
      </template>
      <template
        slot="checkInImageId"
        slot-scope="text,records"
      >
        <a
          :link="records.checkInImageId"
          @click="openImgDialog(records.checkInImageId, '入园照片')"
        >查看</a>
      </template>
      <template
        slot="checkOutImageId"
        slot-scope="text,records"
      >
        <a
          :link="records.checkOutImageId"
          @click="openImgDialog(records.checkOutImageId, '离园照片')"
        >查看</a>
      </template>
      <template
        slot="handImgId"
        slot-scope="text,records"
      >
        <a
          :link="records.handImgId"
          @click="openImgDialog(records.handImgId, '手部照片')"
        >查看</a>
      </template>
      <template
        slot="mouthImgId"
        slot-scope="text,records"
      >
        <a
          :link="records.mouthImgId"
          @click="openImgDialog(records.mouthImgId, '嘴部照片')"
        >查看</a>
      </template>
      <template
        slot="eyeImgId"
        slot-scope="text,records"
      >
        <a
          :link="records.eyeImgId"
          @click="openImgDialog(records.eyeImgId, '眼部照片')"
        >查看</a>
      </template>
    </a-table>
    <a-modal
      v-model="dialogVisible"
      :title="dialogTitle"
      @cancel="closeImgDialog"
      :footer="null"
    >
      <img
        :src="dialogImg"
        class="sign-list-modal-image"
      >
    </a-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Api from "src/api/index.js";

export default {
    name: "SignList",
    props: {
        kindergartenId: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false,
            dialogTitle: '',
            dialogImg: '',
            dataSource: [],
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    scopedSlots: { customRender: "index" },
                },
                {
                    title: "学生姓名",
                    dataIndex: "studentName",
                },
                {
                    title: "卡号",
                    dataIndex: "cardNo",
                },
                {
                    title: "入园签到时间",
                    dataIndex: "checkInSignTime",
                    scopedSlots: { customRender: "checkInSignTime" },
                },
                {
                    title: "健康检测时间",
                    dataIndex: "bodyCheckTime",
                    scopedSlots: { customRender: "bodyCheckTime" },
                },
                {
                    title: "离园打卡时间",
                    dataIndex: "checkOutSignTime",
                    scopedSlots: { customRender: "checkOutSignTime" },
                },
                {
                    title: "晨检明细结果",
                    dataIndex: "morningCheckDetail",
                },
                {
                    title: "晨检总体结果",
                    dataIndex: "morningCheckOverview",
                },
                {
                    title: "状态",
                    dataIndex: "status",
                },
                {
                    title: "体温",
                    dataIndex: "temperature",
                },
                {
                    title: "入园照片",
                    dataIndex: "checkInImageId",
                    key: "checkInImageId",
                    scopedSlots: { customRender: "checkInImageId" },
                },
                {
                    title: "离园照片",
                    dataIndex: "checkOutImageId",
                    scopedSlots: { customRender: "checkOutImageId" },
                },
                {
                    title: "手部照片",
                    dataIndex: "handImgId",
                    scopedSlots: { customRender: "handImgId" },
                },
                {
                    title: "嘴部照片",
                    dataIndex: "mouthImgId",
                    scopedSlots: { customRender: "mouthImgId" },
                },
                {
                    title: "眼部照片",
                    dataIndex: "eyeImgId",
                    scopedSlots: { customRender: "eyeImgId" },
                },
            ],
        };
    },

    created() {
        this.getKindergartenSignDetail();
    },

    methods: {
        getKindergartenSignDetail() {
            this.loading = true;
            Api.getKindergartenSignDetail({
                date_format: dayjs().format("YYYY-MM-DD"),
                kindergarten_id: this.kindergartenId,
                page_num: this.pageNum,
                page_size: this.pageSize,
            })
                .then(({ data }) => {
                    this.dataSource = data?.list || [];
                    this.total = data?.total || 0;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onTableChange(pagination) {
            this.pageNum = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getKindergartenSignDetail();
        },
        openImgDialog(imgSrc, title) {
            this.dialogVisible = true;
            this.dialogImg = imgSrc;
            this.dialogTitle = title;
        },
        closeImgDialog() {
            this.dialogVisible = false;
            this.dialogImg = '';
            this.dialogTitle = '';
        },
        getFormatTime(time) {
            return dayjs(time).format("YYYY-MM-DD hh:mm:ss")
        }
    },
};
</script>

<style lang="scss">
.sign-data-table {
    margin-bottom: 20px;
    .ant-table-thead, .ant-table-tbody {
        tr {
            th {
                background: rgb(10, 39, 50);
                color: $base-font-color;
            }
            td {
                background: transparent;
                color: $base-font-color;
            }
            &:hover {
                th {
                    background: rgb(10, 39, 50);
                }
                td {
                    background: rgba(255,255,255,0.1) !important;
                    color: $base-font-color;
                }
            }
        }
        a {
            color: $base-font-color;
            &:hover {
                color: rgba(255,255,255,0.5);
            }
        }
    }
    .ant-table-placeholder {
        background: transparent;
    }
    .ant-empty-description {
        color: $base-font-color;
    }
}
.sign-list-modal-image {
    width: 100%;
}

</style>
