import { render, staticRenderFns } from "./BlockHeader.vue?vue&type=template&id=c44a5f40&scoped=true&"
import script from "./BlockHeader.vue?vue&type=script&lang=js&"
export * from "./BlockHeader.vue?vue&type=script&lang=js&"
import style0 from "./BlockHeader.vue?vue&type=style&index=0&id=c44a5f40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c44a5f40",
  null
  
)

export default component.exports