import apiList from './url';
import apiFactory from './factory';

const Api = {};

for (let key in apiList) {
    Api[key] = apiFactory(apiList[key]);
}

export default Api;
