<template>
  <div class="block-header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlockHeader',

  props: {
    title: {
        type: String,
        default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.block-header {
    height: 60px;
    padding: 0 8px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
}
</style>
