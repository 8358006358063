export default {
    getKindergartenDetail: {
        url: 'hoogoo/cockpit/kindergarten/detail',
    },
    getKindergartenSignDetail: {
        url: 'hoogoo/cockpit/sign/kindergarten/detail',
        method: 'post'
    },
    getKindergartenSignHistory: {
        url: 'hoogoo/cockpit/sign/kindergarten/history',
        method: 'post'
    },
    getKindergartenSignOverview: {
        url: 'hoogoo/cockpit/sign/kindergarten/overview',
        method: 'post'
    },
};
