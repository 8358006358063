import http from 'axios';

export default api => {
    const method = api.method === 'post' ? 'post' : 'get';
    const pathExp = /(?:\$\{)([a-zA-Z0-9]+)(?:\})/gi;
    const matches = api.url.match(pathExp);

    api.variables = matches ? matches.map(item => item.substring(2, item.length - 1)) : [];

    return async (params, options = {}) => {
        if (api.url.indexOf('${') > -1) {
            // 对${xxx}的替换
            // eslint-disable-next-line
            let fn = new Function('params', 'with(params) { return `' + api.url + '`}');
            api.reUrl = fn(params);
        }

        // 删除params中被${xxx}替换掉的变量
        api.variables.forEach(item => {
            delete params[item];
        });

        let {path, payload, stream} = options;
        let localUrl = api.reUrl || api.url;
        let url = path ? localUrl + path : localUrl;

        url = './' + url;

        return http.request({
            url,
            method,
            ...payload,
            [method === 'get' ? 'params' : 'data']: params
        }).then(result => {
            const data = result.data;
            if (stream) {
                return result;
            }
            if (data.result_code === "success") {
                return data;
            } else {
                return Promise.reject(data);
            }
        }).catch(res => {
            if (http.isCancel(res)) {
                console.log('Request canceled', res.message);
            } else {
                return Promise.reject(res);
            }
        });
    };
};
