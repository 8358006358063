import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from 'src/views/Index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'index',
    component: Index,
  },
  // 默认跳转到index
  {
    path: '/',
    redirect: '/index',
  },
]
const router = new VueRouter({
  routes
})

export default router