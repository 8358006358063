<template>
  <div class="header">
    <div class="header-logo">
      <img
        src="/src/assets/image/logo.png"
        alt=""
      >
    </div>
    <div class="user">
      <span>欢迎</span>
      <span>渤海银行</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',

  data () {
    return {

    }
  }

}
</script>

<style lang="scss" scoped>
.header {
    height: 60px;
    padding: 0 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}
</style>
