<template>
  <div class="sign-history">
    <block-header>学生签到历史</block-header>
    <dv-border-box-12>
      <Chart :data="data" />
    </dv-border-box-12>
  </div>
</template>

<script>
import Chart from './Chart.vue';
import BlockHeader from 'src/components/BlockHeader.vue';
import Api from 'src/api/index.js';
import dayjs from 'dayjs'

export default {
    name: "SignHistoryIndex",

    props: {
        kindergartenId: {
            type: String,
            default: ""
        },
    },

    data () {
        return {
            data: {
                category: [],
                seriesData: []
            }
        };
    },

    components: {
        Chart,
        BlockHeader
    },

    mounted () {
        this.getKindergartenSignHistory();
    },

    methods: {
        getKindergartenSignHistory() {
            this.loading = true;
            Api.getKindergartenSignHistory({
                date_format: dayjs().format('YYYY-MM-DD'),
                kindergarten_id: this.kindergartenId
            }).then(({data}) => {
                this.data.category = data.map(item => {return item.date});
                this.data.seriesData = data.map(item => {return item.signedCount});
            }).finally(() => {
                this.loading = false;
            })
        }
    }
};
</script>