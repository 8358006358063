<template>
  <div
    class="sign-overview"
    v-if="!loading"
  >
    <block-header>
      <div>
        <span>今天（{{ today }}）</span>
        <span>签到及健康检测</span>
      </div>
      <!-- <div>完整数据 >></div> -->
    </block-header>
    <div class="sign-overview-chart">
      <div class="sign-overview-chart-item">
        <p>实时签到人数</p>
        <dv-digital-flop
          :config="todaySignedCount"
          :style="style"
        />
      </div>
      <div class="sign-overview-chart-item">
        <p>签到率</p>
        <dv-percent-pond
          :config="signedRate"
          :style="style"
        />
      </div>
      <div class="sign-overview-chart-item">
        <p>学费宝订单签到人数</p>
        <dv-digital-flop
          :config="xfbSignedCount"
          :style="style"
        />
      </div>
      <div class="sign-overview-chart-item">
        <p>学费宝订单签到率</p>
        <dv-percent-pond
          :config="xfbSignedRate"
          :style="style"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlockHeader from 'src/components/BlockHeader.vue';
import dayjs from 'dayjs'
import Api from 'src/api/index.js';

export default {
    name: "SignOverview",
    props: {
        kindergartenId: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            signedRate: {
                value: 0
            },
            todaySignedCount: {
                number: [0],
                content: '{nt}个'
            },
            xfbSignedCount: {
                number: [0],
                content: '{nt}个'
            },
            xfbSignedRate: {
                value: 0
            },
            loading: false
        }
    },
    components: {
        BlockHeader,
    },

    computed: {
        today() {
            return dayjs().format('YYYY年MM月DD日');
        },
        style() {
            return "width:160px;height:80px;"
        }
    },

    created() {
        this.getKindergartenSignOverview();
    },
    methods: {
        getKindergartenSignOverview() {
            this.loading = true;
            Api.getKindergartenSignOverview({
                date_format: dayjs().format('YYYY-MM-DD'),
                kindergarten_id: this.kindergartenId
            }).then(({data}) => {
                this.signedRate.value = data.signedRate || 0;
                this.xfbSignedRate.value = data.xfbSignedRate || 0;
                this.todaySignedCount.number[0] = data.todaySignedCount || 0;
                this.xfbSignedCount.number[0] = data.xfbSignedCount || 0;
            }).finally(() => {
              this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.sign-overview {
  &-chart {
    display: flex;
    padding: 20px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 40px;
    }
  }
}
</style>
