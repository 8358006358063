import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import './assets/style/reset.scss';

import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

Vue.use(dataV);
Vue.use(Antd);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
