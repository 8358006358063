<template>
  <div class="base-info">
    <block-header>基础信息</block-header>
    <div class="base-info-content">
      <div class="base-info-content-left">
        <a-row>
          园所规模：室外 {{ baseInfo.acreage || 0 }} 平米 室内 {{ baseInfo.indoorAcreage || 0 }} 平米
        </a-row>
        <a-row>
          <a-col :lg="8">
            核定班级数：{{ baseInfo.approvedClassNum || 0 }}
          </a-col>
          <a-col :lg="8">
            现有班级数：{{ baseInfo.existClassNum || 0 }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="8">
            学校满员数：{{ baseInfo.studentsMaxNum || 0 }}
          </a-col>
          <a-col :lg="8">
            当前在校学生数：{{ baseInfo.studentNum || 0 }}
          </a-col>
        </a-row>
        <a-row>
          教职工数：{{ baseInfo.teacherNum || 0 }}
        </a-row>
      </div>
      <div class="base-info-content-right">
        <a-row>
          收费公示信息
        </a-row>
        <div class="content-box">
          <img
            :src="baseInfo.feePublicityPictures && baseInfo.feePublicityPictures[0]"
            class="img"
            @click="openImgDialog"
          >
          <div>
            <a-row>
              保教费标准（元/月/人）：{{ baseInfo.educationFeeStandard || 0 }}
            </a-row>
            <a-row>
              伙食标准费（元/月/人）：{{ baseInfo.boardExpenseStandard || 0 }}
            </a-row>
            <a-row class="d-flex">
              <div>
                一年收费月数（月）：{{ baseInfo.feeMonthsInYear || 0 }}
              </div>
              <div>
                政府补贴（元/月/人）：{{ baseInfo.governmentSubsidies || 0 }}
              </div>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="dialogVisible"
      title="收费公示照片"
      @cancel="closeImgDialog"
      :footer="null"
    >
      <img
        :src="baseInfo.feePublicityPictures && baseInfo.feePublicityPictures[0]"
        class="sign-list-modal-image"
      >
    </a-modal>
  </div>
</template>

<script>
import BlockHeader from 'src/components/BlockHeader.vue';

export default {
    name: "BaseInfo",

    props: {
        baseInfo: {
            type: Object,
            default: (() => {})
        }
    },

    data() {
      return {
        dialogVisible: false,
        dialogImg: ''
      }
    },

    components: {
        BlockHeader
    },

    methods: {
      openImgDialog(imgSrc) {
          this.dialogVisible = true;
          this.dialogImg = imgSrc;
      },
    }
}
</script>

<style lang="scss" scoped>
.base-info {
  &-content {
    display: flex;
    padding: 20px;
    &-left, &-right {
      flex: 1;
      .ant-row {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      .content-box {
        display: flex;
        img {
          width: 160px;
          height: 100px;
          margin-right: 40px;
          cursor: pointer;
        }
        .d-flex {
            display: flex;
            div {
                margin-right: 20px;
            }
        }
      }
    }
  }
  &-modal-image {
    width: 100%;
  }
}
</style>
