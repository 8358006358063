<template>
  <div>
    <Echart
      :options="options"
      height="480px"
      width="100%"
    />
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
    name: "SignHistoryChart",

    props: {
        data: {
            type: Object,
            default: () => ({})
        },
    },

    data () {
        return {
            options: {},
        };
    },

    components: {
        Echart,
    },

    watch: {
        data: {
            handler (newData) {
                this.options = {
                    tooltip: {
                        trigger: "axis",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        axisPointer: {
                            type: "shadow",
                            label: {
                            show: true,
                            backgroundColor: "#7B7DDC"
                            }
                        }
                    },
                    grid: {
                        x: "6%",
                        width: "88%",
                        y: "10%"
                    },
                    xAxis: {
                        data: newData.category,
                        axisLine: {
                            lineStyle: {
                            color: "#B4B4B4"
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: [
                        {
                            name: "人数",
                            splitLine: { show: false },
                            axisLine: {
                                lineStyle: {
                                    color: "#B4B4B4"
                                }
                            },
                            axisLabel: {
                                formatter: "{value}"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "已签到",
                            type: "bar",
                            barWidth: 20,
                            itemStyle: {
                                normal: {
                                    barBorderRadius: [10, 10, 0, 0],
                                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "#956FD4" },
                                            { offset: 1, color: "#3EACE5" }
                                        ])
                                    }
                                },
                            data: newData.seriesData
                        }
                    ]
                }
            },
            immediate: true,
            deep: true
        },
    },
}
</script>